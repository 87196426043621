import GraphQLJSON from 'graphql-type-json';
import createInMemoryCache from './cache';

export const resolvers = {
  Query: {},
  Mutation: {},
  JSON: GraphQLJSON,
};

export const createCache = createInMemoryCache;

export default {
  resolvers,
  createCache,
};
