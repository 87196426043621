import isLocalhost from './isLocalhost';
import isDevServer from './isDevServer';
import isFeatureServer from './isFeatureServer';
import isStagingServer from './isStagingServer';

const isRunningOnProd =
  !isLocalhost() && !isDevServer() && !isFeatureServer() && !isStagingServer();

const isProd = () => isRunningOnProd;

export default isProd;
