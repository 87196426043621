// Convert location object to object with short keys, for storing in cookie
const getUserLocationWithShortKeys = longLocation => {
  if (longLocation) {
    return {
      zc: longLocation.zip, // zipcode
      ct: longLocation.city, // city
      st: longLocation.state, // state name
      sc: longLocation.stateShortName, // state code
      co: longLocation.country, // country
    };
  }

  return {};
};

// Convert location object with short keys (from cookie) to object with long
// keys for use within app
const getUserLocationWithLongKeys = shortLocation => {
  if (shortLocation) {
    return {
      zip: shortLocation.zc,
      city: shortLocation.ct,
      state: shortLocation.st,
      stateShortName: shortLocation.sc,
      country: shortLocation.co,
    };
  }

  return {};
};

export { getUserLocationWithShortKeys, getUserLocationWithLongKeys };
