import isServer from 'commons/isServer';

let isNavigating = false;

export function sendLuxUserData(name, value) {
  if (isServer()) {
    return;
  }
  if (!window.LUX) {
    return;
  }
  window.LUX.addData(name, value);
}

export function markLuxPageNavigationStart() {
  if (isServer()) {
    return;
  }
  if (!window.LUX) {
    return;
  }
  window.LUX.init();
  isNavigating = true;
}

export function markLuxPageNavigationEnd() {
  if (isServer() || !isNavigating) {
    return;
  }
  if (!window.LUX) {
    return;
  }
  window.LUX.send();
  isNavigating = false;
}

export function setLuxPageLabel(pageName) {
  if (isServer()) {
    return;
  }
  if (!window.LUX) {
    return;
  }
  window.LUX.label = pageName;
}

export const LUX_USER_DATA = {
  ADD_TO_CART: 'Add to Cart',
  PDP: 'PDP',
  PURCHASED: 'Purchased',
  PURCHASED_CART_VALUE: 'Purchased Cart Value',
  ORDER_COMPLETED: 'orderCompleted',
  ORDER_COMPLETED_REVENUE: 'orderCompleted.revenue',
};

export const LUX_PAGE_LABELS = {
  PDPv1: 'PDPv1',
  PDPv2: 'Product',
  UNSET: null,
  CATEGORY: 'Category',
  HOME: 'Home',
  CHECKOUT: 'Checkout',
};
