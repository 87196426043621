const getFocusableElements = parentElement => {
  if (parentElement) {
    const focusableElements = parentElement.querySelectorAll(
      'a[href]:not([tabindex="-1"]), area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.hidden), [tabindex="0"]'
    );

    const focusableElementsArray = Array.from(focusableElements);
    return focusableElementsArray;
  }

  return [];
};

export default getFocusableElements;
