import * as Sentry from '@sentry/react';
import isClient from './isClient';
import isProd from './isProd';

/** Note: All of these logging functions only log to the browser console in
 * non-production environments, so can be safely used without outputting to the
 * console in prod. The error functions also report any errors to Sentry. */

// This function should be called if there is a critical error which prevents
// users from using core functionality, e.g. if user hits a 404 page or is
// unable to connect to the Alexandria GraphQL API.
export const logCriticalError = (err, type) => {
  const errorArray = ['logCriticalError:', err, type].filter(Boolean);

  if (isClient()) {
    if (!isProd()) {
      // eslint-disable-next-line no-console
      console.error(...errorArray);
    }

    Sentry.withScope(scope => {
      scope.setTag('JB4.0-CRITICAL', 'true');
      scope.setTag('URL', document.location.href);
      scope.setTag('ERRORTYPE', type);
      Sentry.captureException(err);
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(...errorArray);
  }
};

// This function should be called if a non-critical error occurs
export const logError = (err, messageOrTags, extras) => {
  const tags =
    typeof messageOrTags === 'string'
      ? { message: messageOrTags }
      : messageOrTags;

  const errorArray = ['logError:', err, tags, extras].filter(Boolean);

  if (isClient()) {
    if (!isProd()) {
      // eslint-disable-next-line no-console
      console.error(...errorArray);
    }

    Sentry.withScope(scope => {
      scope.setTag('URL', document.location.href);
      if (tags) {
        Object.keys(tags).forEach(key => {
          scope.setTag(key, tags[key]);
        });
      }
      if (extras) {
        Object.keys(extras).forEach(key => {
          scope.setExtra(key, extras[key]);
        });
      }
      Sentry.captureException(err);
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(...errorArray);
  }
};

// This function should be called to log any extra debugging info to the console
export const logInfo = (...debugData) => {
  if (!isProd()) {
    const debugDataArray = ['logInfo:', ...debugData].filter(Boolean);
    // eslint-disable-next-line no-console
    console.info(...debugDataArray);
  }
};
