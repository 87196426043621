import { gql } from '@apollo/client';

export const FAVORITE_PRODUCT = gql`
  mutation userFavoriteAction(
    $productID: Int!
    $options: FavOptionInput
    $keepFavorited: Boolean
  ) {
    userFavoriteAction(
      productID: $productID
      options: $options
      keepFavorited: $keepFavorited
    ) {
      id
      status
      options {
        optionName
        optionId
        optionType
      }
    }
  }
`;

export const FAVORITE_PRODUCT_V2 = gql`
  mutation userFavoriteActionV2($productID: Int!) {
    userFavoriteActionV2(productID: $productID) {
      id
      status
      __typename
    }
  }
`;

export const NEWSLETTER_SIGNUP = gql`
  mutation newsletterSignUp(
    $email: String!
    $source: String
    $full_name: String
  ) {
    newsletterSignUp(email: $email, source: $source, full_name: $full_name)
  }
`;

export const TRADE_NEWSLETTER_SIGNUP = gql`
  mutation tradeNewsletterSignUp($email: String!, $source: String) {
    tradeNewsletterSignUp(email: $email, source: $source)
  }
`;

export const ADD_TO_CART = gql`
  mutation AddToCart(
    $id: Int!
    $quantity: Int!
    $pickLater: Boolean
    $productOptions: [ProductOptionsInput]
    $selectedProtectionPlanId: Int
    $zipCode: String
  ) {
    addToCart(
      id: $id
      quantity: $quantity
      pickLater: $pickLater
      productOptions: $productOptions
      selectedProtectionPlanId: $selectedProtectionPlanId
      zipCode: $zipCode
    ) {
      cart {
        id
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart($id: String!) {
    removeFromCart(id: $id) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const ADD_MULTIPLE_TO_CART = gql`
  mutation AddMultipleToCart($products: String!, $zipCode: String) {
    addMultipleToCart(products: $products, zipCode: $zipCode) {
      id
      name
      quantity
      slug
      sku
      imageSKU
      pickLater
    }
  }
`;

export const VERIFY_POS_CHARGE_ON_ORDER = gql`
  mutation verifyPOSChargeOnOrder($input: VerifyPOSChargeOnOrderInput!) {
    verifyPOSCharge: verifyPOSChargeOnOrder(input: $input) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const LOG_FAILED_POS_CHARGE_ON_ORDER = gql`
  mutation logFailedPOSChargeOnOrder($input: LogFailedPOSChargeOnOrderInput!) {
    logFailedPOSCharge: logFailedPOSChargeOnOrder(input: $input) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const REQUEST_POS_CHARGE_FROM_READER = gql`
  mutation requestPOSChargeFromReader($input: RequestPOSChargeInput!) {
    requestPOSCharge: requestPOSChargeFromReader(input: $input) {
      errors {
        code
        field
        message
      }
      inStoreContext {
        id
        status
      }
    }
  }
`;

export const REQUEST_POS_CHARGE_CANCEL_FROM_READER = gql`
  mutation requestPOSChargeCancelFromReader(
    $email: String!
    $inStoreContextId: String!
  ) {
    requestPOSChargeCancel: requestPOSChargeCancelFromReader(
      email: $email
      inStoreContextId: $inStoreContextId
    ) {
      inStoreContext {
        id
        status
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation placeOrder(
    $order: String!
    $checkoutRef: String
    $quick_checkout_guest: Boolean
  ) {
    placeOrder(
      order: $order
      checkoutRef: $checkoutRef
      quick_checkout_guest: $quick_checkout_guest
    ) {
      errors {
        code
        field
        message
      }
      orderId
      customerId
      debug
    }
  }
`;

export const SEND_CART_TO_EMAIL = gql`
  mutation SendCartToEmail(
    $email: SanitizedEmailInputString!
    $retail_store_id: String
    $entered_by: String
    $designer_name: String
    $designer_service_type: String
    $zipCode: String
  ) {
    shareCart(
      email: $email
      retail_store_id: $retail_store_id
      entered_by: $entered_by
      designer_name: $designer_name
      designer_service_type: $designer_service_type
      zipCode: $zipCode
    ) {
      status
      products
    }
  }
`;

export const SAVE_CART_STORE_DETAILS = gql`
  mutation saveCartStoreDetails($store_id: String, $entered_by: String) {
    saveCartStoreDetails(store_id: $store_id, entered_by: $entered_by) {
      success
      errors {
        code
        message
      }
    }
  }
`;

export const CLEAR_CART_STORE_DETAILS = gql`
  mutation clearCartStoreDetails($store_id: String) {
    clearCartStoreDetails(store_id: $store_id) {
      success
      errors {
        code
        message
      }
    }
  }
`;

export const MERGE_SHARED_CART = gql`
  mutation mergeShareCart($saved_cart_token: String!, $zipCode: String) {
    mergeShareCart(saved_cart_token: $saved_cart_token, zipCode: $zipCode) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const EMPTY_CART = gql`
  mutation emptyTheCart {
    emptyTheCart
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput) {
    updateOrder(input: $input) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_ORDER_AFFIRM_UPCHARGE = gql`
  mutation updateOrderAffirmUpCharge($input: UpdateOrderAffirmUpChargeInput) {
    updateOrderAffirmUpCharge(input: $input) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login(
    $email: SanitizedEmailInputString!
    $password: String!
    $rememberMe: Boolean
    $tempUserId: String
  ) {
    login(
      email: $email
      password: $password
      rememberMe: $rememberMe
      tempUserId: $tempUserId
    ) {
      user {
        access_token
        email
        first_name
        group_id
        id
        is_designer
        is_impersonate
        last_name
        refresh_token
        user_discount
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation signup(
    $first_name: SanitizedNameInputString!
    $last_name: SanitizedNameInputString!
    $email: SanitizedEmailInputString!
    $password: String!
    $tempUserId: String
    $newsletter_opt_in: Boolean
  ) {
    signup(
      email: $email
      password: $password
      first_name: $first_name
      last_name: $last_name
      tempUserId: $tempUserId
      newsletter_opt_in: $newsletter_opt_in
    ) {
      user {
        access_token
        email
        first_name
        group_id
        id
        is_designer
        is_impersonate
        last_name
        refresh_token
        user_discount
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const IMPERSONATE = gql`
  mutation impersonate($hash: String!) {
    impersonate(hash: $hash) {
      user {
        access_token
        email
        first_name
        group_id
        id
        is_designer
        is_impersonate
        last_name
        refresh_token
        user_discount
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation continueWithFacebook(
    $fbLoginData: JSON
    $tempUserId: String
    $newsletter_opt_in: Boolean
  ) {
    continueWithFacebook(
      fbLoginData: $fbLoginData
      tempUserId: $tempUserId
      newsletter_opt_in: $newsletter_opt_in
    ) {
      user {
        access_token
        email
        first_name
        group_id
        id
        is_designer
        is_impersonate
        last_name
        refresh_token
        user_discount
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email) {
      success
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $new_password: String!
    $confirm_new_password: String!
    $token: String!
  ) {
    resetPassword(
      new_password: $new_password
      confirm_new_password: $confirm_new_password
      token: $token
    ) {
      success
      message
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const UPDATE_SHIPPING_FORM = gql`
  mutation updateShippingFormData(
    $address: String!
    $apartment: String!
    $city: String!
    $email_address: String!
    $first_name: String!
    $last_name: String!
    $phone_number: Int!
    $zipcode: Int!
    $state: String!
  ) {
    updateShippingFormData(
      address: $address
      apartment: $apartment
      city: $city
      email_address: $email_address
      first_name: $first_name
      last_name: $last_name
      phone_number: $phone_number
      zipcode: $zipcode
      state: $state
    ) @client
  }
`;

export const CUSTOMER_CHANGE_PASSWORD_MUTATION = gql`
  mutation customerChangePassword($update: ChangePasswordInput!) {
    customerChangePassword(update: $update) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_PERSONAL_INFO_MUTATION = gql`
  mutation updatePersonalInfo($update: UpdatePersonalInfoInput) {
    updatePersonalInfo(update: $update) {
      success
      message
      first_name
      last_name
    }
  }
`;
export const SUBMIT_CUSTOMER_PHOTO = gql`
  mutation submitCustomerPhoto(
    $photo_image: String!
    $photo_comment: String!
    $cart_id: Int!
    $product_id: Int!
    $image_name: String!
  ) {
    submitCustomerPhoto(
      photo_image: $photo_image
      photo_comment: $photo_comment
      cart_id: $cart_id
      product_id: $product_id
      image_name: $image_name
    ) {
      success
      message
    }
  }
`;

export const CREATE_PRODUCT_REVIEW = gql`
  mutation createProductReview(
    $cart_id: Int!
    $review_rate: Int!
    $review_title: String!
    $review_comment: String!
    $review_full_name: String!
    $fabric_review_rate: Int
    $fabric_review_comment: String
  ) {
    review: createProductReview(
      cart_id: $cart_id
      review_rate: $review_rate
      review_title: $review_title
      review_comment: $review_comment
      review_full_name: $review_full_name
      fabric_review_rate: $fabric_review_rate
      fabric_review_comment: $fabric_review_comment
    ) {
      errors {
        code
        field
        message
      }
      review_id
    }
  }
`;

export const UPDATE_PRODUCT_REVIEW = gql`
  mutation updateProductReview(
    $cart_id: Int!
    $review_id: Int!
    $review_rate: Int!
    $review_title: String!
    $review_comment: String!
    $review_full_name: String!
    $fabric_review_rate: Int
    $fabric_review_comment: String
  ) {
    review: updateProductReview(
      cart_id: $cart_id
      review_id: $review_id
      review_rate: $review_rate
      review_title: $review_title
      review_comment: $review_comment
      review_full_name: $review_full_name
      fabric_review_rate: $fabric_review_rate
      fabric_review_comment: $fabric_review_comment
    ) {
      errors {
        code
        field
        message
      }
      review_id
    }
  }
`;

export const ACTIVATE_GIFT_CARD = gql`
  mutation activateGiftCard($gift_card: String!) {
    activateGiftCard(gift_card: $gift_card) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const CREATE_SWATCH_REQUEST = gql`
  mutation createSwatchRequest($create: CreateSwatchInput!) {
    swatchRequest: createSwatchRequest(create: $create) {
      success
      message
      request_id
      customer_id
    }
  }
`;

export const SUBMIT_BUSINESS_APPLICATION = gql`
  mutation submitBusinessApplication($form: BusinessApplicationInput!) {
    businessRequest: submitBusinessApplication(form: $form) {
      success
      message
    }
  }
`;

export const PRODUCT_STOCK_NOTIFICATION = gql`
  mutation notifyMeAsProductInStock($email: String!, $product_id: Int!) {
    notifyMeAsProductInStock(email: $email, product_id: $product_id) {
      success
      message
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateNotificationSettings($update: NotificationSettingsInput!) {
    updateRequest: updateNotificationSettings(update: $update) {
      success
      message
    }
  }
`;

export const UPDATE_CART_ITEM = gql`
  mutation updateCartItem(
    $cart_id: String!
    $product_id: String!
    $quantity: Int
    $pickLater: Boolean
    $productOptions: [ProductOptionsInput]
    $zipCode: String
  ) {
    updateRequest: updateCartItem(
      cart_id: $cart_id
      product_id: $product_id
      quantity: $quantity
      pickLater: $pickLater
      productOptions: $productOptions
      zipCode: $zipCode
    ) {
      success
      errors {
        message
      }
    }
  }
`;

export const UPDATE_CART_ITEM_PROTECTION_PLAN = gql`
  mutation updateCartItemProtectionPlan(
    $cart_id: Int!
    $plan_id: Int
    $protection_plan: Boolean
  ) {
    updateCartItemProtectionPlan: updateCartItemProtectionPlan(
      cart_id: $cart_id
      plan_id: $plan_id
      protection_plan: $protection_plan
    ) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_CART_PROTECTION_PLAN = gql`
  mutation updateCartProtectionPlan($protection_plan: Boolean!) {
    updateCartProtectionPlan: updateCartProtectionPlan(
      protection_plan: $protection_plan
    ) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const VALIDATE_AND_APPLY_COUPON_CODE_TO_CART = gql`
  mutation validateAndApplyCouponCodeToCart($coupon_code: String!) {
    validateAndApply: validateAndApplyCouponCodeToCart(
      coupon_code: $coupon_code
    ) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const REMOVE_COUPON_FROM_CART = gql`
  mutation removeSingleCartAppliedCoupon($coupon_code: String!) {
    removeSingleCartAppliedCoupon: removeSingleCartAppliedCoupon(
      coupon_code: $coupon_code
    ) {
      success
      message
    }
  }
`;

export const SET_CHECKOUT_DATA = gql`
  mutation setCheckoutData($checkout: JSON!) {
    setCheckoutData(checkout: $checkout)
  }
`;

export const SET_INSTORE_CHECKOUT_DATA = gql`
  mutation setInstoreCheckoutData($checkout: JSON!, $affirmInstore: Boolean) {
    setCheckoutData(checkout: $checkout, affirmInstore: $affirmInstore)
  }
`;

export const ERASE_CHECKOUT_DATA = gql`
  mutation eraseCheckoutData($checkoutRef: String) {
    eraseCheckoutData(checkoutRef: $checkoutRef)
  }
`;

export const CREATE_DSR_REQUEST = gql`
  mutation createDSRRequest($input: DSRInput) {
    createDataSubmitRequest(input: $input) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const PREPARE_QUOTE_CONVERT_TO_ORDER = gql`
  mutation prepareQuoteConvertToOrder($quote_id: Int!) {
    prepareQuoteConvertToOrder(quote_id: $quote_id) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const CONVERT_QUOTE_TO_ORDER = gql`
  mutation convertQuoteToOrder($input: ConvertQuoteToOrderInput) {
    convertQuoteToOrder(input: $input) {
      order_id
      errors {
        code
        field
        message
      }
    }
  }
`;

export const ADD_TO_RECENTLY_VIEWED = gql`
  mutation addToRecentlyViewed($productIds: [String]) {
    addToRecentlyViewed(productIds: $productIds)
  }
`;

export const CREATE_ORDER_ISSUE = gql`
  mutation createOrderIssues($input: CreateOrderIssuesInput!) {
    createOrderIssues(input: $input) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;

export const SUBMIT_DESIGN_SERVICES_QUIZ = gql`
  mutation submitDesignQuiz(
    $quizBody: [QuizAnswerInput]
    $email: SanitizedEmailInputString!
    $first_name: SanitizedNameInputString!
    $last_name: SanitizedNameInputString!
    $ticket_id: String
  ) {
    submitDesignQuiz(
      quizBody: $quizBody
      email: $email
      first_name: $first_name
      last_name: $last_name
      ticket_id: $ticket_id
    ) {
      success
      message
    }
  }
`;

export const CREATE_KUSTOMER_TICKET = gql`
  mutation createKustomerTicket($input: CreateKustomerTicketInput) {
    createKustomerTicket(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const SUBMIT_DESIGN_SERVICES_QUIZ_FOLLOWUP = gql`
  mutation submitDesignQuizFollowUp(
    $quizBody: [QuizAnswerInput]
    $ticket_id: String
    $recaptcha_value: String
    $isForInstore: Boolean
    $instoreData: DesignQuizInstoreData
  ) {
    submitDesignQuizFollowUp(
      quizBody: $quizBody
      ticket_id: $ticket_id
      recaptcha_value: $recaptcha_value
      isForInstore: $isForInstore
      instoreData: $instoreData
    ) {
      success
      message
      errors {
        code
        message
      }
    }
  }
`;

export const SAVE_IN_STORE_DESIGN_SERVICE_QUIZ = gql`
  mutation saveInStoreDesignServiceQuiz(
    $storeName: String!
    $input: InStoreDesignServiceQuizInput!
  ) {
    saveInStoreDesignServiceQuiz(storeName: $storeName, input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const SAVE_IN_STORE_DESIGN_SERVICE_QUIZ_2 = gql`
  mutation saveInStoreDesignServiceQuizPart2(
    $storeName: String!
    $quizInput: JSON
  ) {
    saveInStoreDesignServiceQuizPart2(
      storeName: $storeName
      quizInput: $quizInput
    ) {
      success
      errors {
        message
      }
    }
  }
`;

export const SAVE_IN_STORE_DESIGN_SERVICE_QUIZ_3 = gql`
  mutation saveInStoreDesignServiceQuizPart3(
    $kustomerId: String!
    $quizInput: JSON
  ) {
    saveInStoreDesignServiceQuizPart3(
      kustomerId: $kustomerId
      quizInput: $quizInput
    ) {
      success
      errors {
        message
      }
    }
  }
`;

export const SUBMIT_IN_STORE_DESIGN_SERVICE_QUIZ = gql`
  mutation submitInStoreDesignServiceQuiz(
    $input: InStoreDesignServiceQuizCaravelInput!
    $storeName: String!
  ) {
    submitInStoreDesignServiceQuiz(input: $input, storeName: $storeName) {
      success
      response
      errors {
        message
      }
    }
  }
`;

export const APPLY_CUSTOMER_GIFTS_TO_CART = gql`
  mutation applyCustomerGiftsToCart($apply: Boolean) {
    applyCustomerGiftsToCart(apply: $apply) {
      success
    }
  }
`;

export const AFFIRM_INSTORE_INITIATE = gql`
  mutation affirmInstoreCheckoutInitiate(
    $input: affirmInstoreCheckoutInitiateInput
  ) {
    affirmInstoreCheckoutInitiate(input: $input) {
      success
      errors
      message
      redirect_url
    }
  }
`;
export const ADD_TO_USER_FABRIC_FAVS = gql`
  mutation addToUserFabricFavs($userId: Int, $materialId: Int) {
    userFabricFavs: addToUserFabricFavs(
      userId: $userId
      materialId: $materialId
    )
  }
`;
export const REMOVE_FABRIC_FROM_USER_FABRIC_FAVS = gql`
  mutation removeFabricFromUserFabricFavs($userId: Int, $materialId: Int) {
    userFabricFavs: removeFabricFromUserFabricFavs(
      userId: $userId
      materialId: $materialId
    )
  }
`;
export const SUBMIT_DS_RETAIL_EMAIL_OPTIN = gql`
  mutation submitDSRetailEmailOptin($input: DSRetailEmailOptinInput) {
    submitDSRetailEmailOptin(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const LOG_BRAINTREE_CLIENT_NONCE_ERROR = gql`
  mutation logBraintreeClientNonceError($email: String!, $grand_total: Float!) {
    logBraintreeClientNonceError(email: $email, grand_total: $grand_total) {
      success
      errors {
        message
      }
    }
  }
`;

const mutations = {
  FAVORITE_PRODUCT,
  NEWSLETTER_SIGNUP,
  TRADE_NEWSLETTER_SIGNUP,
  SEND_CART_TO_EMAIL,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
  PLACE_ORDER,
  LOGIN,
  FACEBOOK_LOGIN,
  LOGOUT,
  UPDATE_SHIPPING_FORM,
  CUSTOMER_CHANGE_PASSWORD_MUTATION,
  UPDATE_PERSONAL_INFO_MUTATION,
  CREATE_PRODUCT_REVIEW,
  UPDATE_PRODUCT_REVIEW,
  ACTIVATE_GIFT_CARD,
  CREATE_SWATCH_REQUEST,
  SUBMIT_BUSINESS_APPLICATION,
  UPDATE_ORDER,
  UPDATE_ORDER_AFFIRM_UPCHARGE,
  PRODUCT_STOCK_NOTIFICATION,
  UPDATE_NOTIFICATION_SETTINGS,
  ADD_MULTIPLE_TO_CART,
  UPDATE_CART_ITEM,
  SET_CHECKOUT_DATA,
  ERASE_CHECKOUT_DATA,
  VALIDATE_AND_APPLY_COUPON_CODE_TO_CART,
  UPDATE_CART_PROTECTION_PLAN,
  CREATE_DSR_REQUEST,
  ADD_TO_RECENTLY_VIEWED,
  REMOVE_COUPON_FROM_CART,
  SUBMIT_DESIGN_SERVICES_QUIZ,
  SAVE_IN_STORE_DESIGN_SERVICE_QUIZ,
  SAVE_IN_STORE_DESIGN_SERVICE_QUIZ_2,
  SUBMIT_IN_STORE_DESIGN_SERVICE_QUIZ,
  CREATE_KUSTOMER_TICKET,
  APPLY_CUSTOMER_GIFTS_TO_CART,
  AFFIRM_INSTORE_INITIATE,
  ADD_TO_USER_FABRIC_FAVS,
  REMOVE_FABRIC_FROM_USER_FABRIC_FAVS,
  SUBMIT_DS_RETAIL_EMAIL_OPTIN,
  LOG_BRAINTREE_CLIENT_NONCE_ERROR,
  FAVORITE_PRODUCT_V2,
};

export default mutations;
