export const isDownArrowKey = event => event.key === 'ArrowDown';
export const isEndKey = event => event.key === 'End';
export const isEnterKey = event => event.key === 'Enter';
export const isEscKey = event => event.key === 'Escape';
export const isHomeKey = event => event.key === 'Home';
export const isLeftArrowKey = event => event.key === 'ArrowLeft';
export const isRightArrowKey = event => event.key === 'ArrowRight';
export const isSpacebarKey = event => event.key === ' ';
export const isTabKey = event => event.key === 'Tab';
export const isUpArrowKey = event => event.key === 'ArrowUp';
export const withShiftKey = event => event.shiftKey;

export const isTabAndNotShiftKey = event =>
  isTabKey(event) && !withShiftKey(event);
export const isTabAndShiftKey = event => isTabKey(event) && withShiftKey(event);
