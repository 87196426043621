import getFocusableElements from './getFocusableElements';

const getLastFocusableElement = parentElement => {
  const focusableElements = getFocusableElements(parentElement);

  if (focusableElements.length) {
    return focusableElements[focusableElements.length - 1];
  }

  return null;
};

export default getLastFocusableElement;
