import getHttpContextValue from './getHttpContextValue';
import isServer from './isServer';
import { HTTP_CONTEXT_FIELDS } from './constants';

const isLighthouseTestRun = () => {
  let uaString = '';
  if (isServer()) {
    uaString = getHttpContextValue(HTTP_CONTEXT_FIELDS.USER_AGENT, '');
  } else {
    uaString = window.navigator.userAgent;
  }
  return /Chrome-Lighthouse|SpeedCurve|PTST/.test(uaString);
};

export default isLighthouseTestRun;
