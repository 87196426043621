// Note: In future we should not normally call this function directly. Whenever
// we need to create a link, instead of importing Link from react-router-dom we
// should instead use our standard Link component, e.g:
// import Link from 'components/consumer/Link';
// Our standard Link component automatically handles external URLs and
// normalizes internal links by using the function below for us.
const normalizeInternalLink = link => {
  if (!link) {
    return '';
  }

  if (
    typeof link === 'string' &&
    link.indexOf('?') === -1 &&
    !link.endsWith('/')
  ) {
    return `${link}/`;
  }
  return link;
};

export default normalizeInternalLink;
