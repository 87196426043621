import getFocusableElements from './getFocusableElements';

const getFirstFocusableElement = parentElement => {
  if (!parentElement) {
    return null;
  }

  const focusableElements = getFocusableElements(parentElement);

  if (focusableElements.length) {
    return focusableElements[0];
  }

  return null;
};

export default getFirstFocusableElement;
